import { dayjs } from '/machinery/dayjs'
import { toPlainText } from '@portabletext/react'

export function date(utcTimestamp) {
  return dayjs(utcTimestamp).utc().format('YYYY-MM-DD')
}

export function wordCount(contentRaw) {
  const content = typeof contentRaw === 'string'
    ? contentRaw
    : toPlainText(contentRaw ?? [])

  return content.match(/\w+/g)?.length ?? 0
}
