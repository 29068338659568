import { reportClientError } from '/machinery/reportClientError'

const { dayjs } = require('/machinery/dayjs')

export function pushToDataLayer(data) {
  window.dataLayer = window.dataLayer || [];
  [].concat(data).forEach(x => {
    try {
      if (!x) throw new Error(`No data given to pushToDataLayer function`)
      window.dataLayer.push(removeUndefinedValues(x))
    } catch (e) {
      reportClientError(e)
    }
  })
}

export function trackInteraction({ title, action, type, index, extraInteractionData = {}, extraMetaData = {} }) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      interaction: {
        title,
        action,
        type,
        index,
        ...extraInteractionData
      },
      ...extraMetaData,
    }
  })
}

export function mapJobForDataLayer(job, jobApplicationId) {
  const educationLevel = getCodeFromFirstIn(job.education_level) ?? undefined
  const jobBranch = getCodeFromFirstIn(job.job_branch) ?? undefined
  const country = getCodeFromFirstIn(job.country) ?? undefined
  const remoteType = getCodeFromFirstIn(job.remote_type) ?? undefined
  const contractType = getCodeFromFirstIn(job.contract_type) ?? undefined
  const { latitude, longitude } = parseGeolocation(job?.location)
  const subexpertise = getCodeFromFirstIn(job.sub_expertise) ?? undefined

  const jobObject = {
    id: job.job_id,
    title: job.job_title,
    subexpertise,
    brand: 'Rabobank',
    experience: educationLevel,
    contract_type: contractType,
    expertise: jobBranch,
    remote_type: remoteType,
    datestart: dayjs(job.date_start).format('DD-MM-YYYY'),
    dateupdated: dayjs(job.date_updated).format('DD-MM-YYYY'),
    salary_currency: job.salary_currency,
    salary_min: job.salary_min,
    salary_max: job.salary_max,
    salary_scale_min: job.salary_scale?.salary_scale_min,
    salary_scale_max: job.salary_scale?.salary_scale_max,
    applicationid: jobApplicationId,
    location: {
      city: job.city,
      postalcode: validatePostalCode(job.postcode),
      country,
      region: job.state,
      lat: latitude ?? undefined,
      long: longitude ?? undefined
    }
  }

  return removeEmptyValues(jobObject)
}

function removeEmptyValues(data) {
  if (typeof data !== 'object' || data === null) return data

  if (Array.isArray(data)) {
    return data
      .filter(x => typeof x !== 'undefined' && x !== '')
      .map(x => removeEmptyValues(x))
  }

  return Object
    .entries(data)
    .filter(([, value]) => typeof value !== 'undefined' && value !== '')
    .map(([key, value]) => [key, removeEmptyValues(value)])
    .reduce(
      (result, [key, value]) => ({ ...result, [key]: value }),
      {}
    )
}

function validatePostalCode(postalCode) {
  if (!postalCode) {
    return undefined
  }

  const hasNumber = /\d/.test(postalCode)
  return (hasNumber && postalCode) || undefined
}

function parseGeolocation(latLongString) {
  if (!latLongString) {
    return {}
  }
  const [lat, long] = latLongString.split(',')
  const latitude = parseFloat(lat)
  const longitude = parseFloat(long)
  return ({ latitude, longitude })
}

/**
 * Has to be fired after typing a minimum of 3 characters in the application form
 */
export function trackApplicationStarted(job) {
  return pushToDataLayer({
    event: 'begin_checkout',
    metadata: { job: mapJobForDataLayer(job) }
  })
}

/**
 * Has to be fired after succesfully submitting the application
 */
export function trackApplicationFinished({ job, jobApplicationId, userInfo }) {
  return pushToDataLayer({
    event: 'purchase',
    metadata: {
      job: mapJobForDataLayer(job, jobApplicationId),
      user: {
        candidateId: userInfo.candidateId
      }
    }
  })
}

function getCodeFromFirstIn(objects) {
  const [firstObject] = objects || []
  return firstObject?.code ?? null
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}
