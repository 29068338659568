import { useClientConfig } from '/machinery/ClientConfig'
import { useLanguage } from '/machinery/I18n'
import { dayjs } from '/machinery/dayjs'
import { ComponentContextProvider as OriginalComponentContextProvider } from '@kaliber/rabobank-components'

export function ComponentContextProvider({ children, isUniversal = false }) {
  const { sanity } = useClientConfig()
  const language = useLanguage()

  return (
    <OriginalComponentContextProvider
      sanityClientConfig={sanity.clients.readOnly}
      {... { isUniversal, dayjs, language }}
    >
      {children}
    </OriginalComponentContextProvider>
  )
}
