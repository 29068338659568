export function cookieConfigurationEvent(configuration) {
  return {
    event: 'cookie_configuration_changed',
    ...cookieConfiguration(configuration)
  }
}

export function cookieConfiguration({ permissions }) {
  return permissions
}
